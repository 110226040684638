<template>
	<v-app>
		<div class="d-flex-column justify-center align-center">
			<!-- Header Segment -->
			<div>
				<common-home-header style="background-color: white;" 
				:callpermissionflag="callpermissionflag"
				:errorDialogue="error_enable"
                :authDialogue="auth_error_enable"
				></common-home-header>
			</div>

			<v-divider></v-divider>

			<div class="navigation-tabs-segment">
				<header-tabs-component :tab_id="1" :is_user_logged_in="isUserLoggedIn"></header-tabs-component>
			</div>

			<v-divider></v-divider>

            <div id="mainContainer">
                <div class="container">
                    <!-- Order Success Segment -->
                    <div class="order-success-segment">
                        <div class="innerDiv" v-if="isDesktopMode && orderPlacedCode==1">
                            <p style="font-size: 20px; font-weight: 600;">
                                {{ $t("Customer.Home.Order_Placed_Successfully") }}
                            </p>
                            <img style="height: 150px; width: 150px; margin-top: -10px;" 
								src="https://s3iconimages.mymedicine.com.mm/OrderSuccessImageFull.svg">
                            <p style="font-size: 12px; font-weight: 400;">
                                {{ $t("Customer.Home.Your_Order_ID_is") }}
                                <span style="font-weight: 600;"> {{ truncateOrderID(orderID) }} </span>
                                <br>
                                <span style="font-size: 10px;">{{ $t("Customer.MyOrders.You_will_receive_an_order_confirmation_email_with_details_of_your_order_shortly") }}</span>
                            </p>
                            <div style="display: flex; justify-content: center;">
                                <v-btn class="goHomeBtn" elevation="0" style="width: 190px;" @click="goToEpharmacyHome()">
                                    {{ $t("Customer.MyOrders.Go_Home") }}
                                </v-btn>
								<div style="padding: 10px; margin-bottom: -5px;"></div>
								<v-btn class="viewOrderDetailsBtn" elevation="0" @click="redirectToParticularOrder()">
									{{ $t("Customer.MyOrders.View_Order_Details") }}
								</v-btn>
                            </div>
                        </div>
						<div class="innerDivWaiting" v-if="isDesktopMode && orderPlacedCode==2">
                            <p style="font-size: 20px; font-weight: 600;">
                                {{ $t("Customer.Home.Payment_Processing") }}
                            </p>
                            <img style="height: 100px; width: 150px; margin-top: 0px; padding-top: 0px;" 
								src="https://s3iconimages.mymedicine.com.mm/PaymentProcessingImage.svg">
							<p style="font-size: 12px; font-weight: 400;">
                                {{ $t("Customer.Home.Your_Order_ID_is") }}
                                <span style="font-weight: 600;"> {{ truncateOrderID(orderID) }} </span>
                                <br>
                                <span style="font-size: 10px;">{{ $t("Customer.Home.Please_Check_Later") }}</span>
                            </p>
                            <div style="display: flex; justify-content: center;">
                                <v-btn class="goHomeBtn" elevation="0" style="width: 320px;" @click="goToEpharmacyHome()">
                                    <span class="white-color-text">{{ $t("Customer.MyOrders.Continue_Shopping") }}</span>
                                </v-btn>
                            </div>
                        </div>
						<div class="innerDivDeclined" v-if="isDesktopMode && orderPlacedCode==3">
                            <p style="font-size: 20px; font-weight: 600;">
                                {{ $t("Customer.Home.Your_Payment_Has_Been_Declined") }}
                            </p>
                            <img style="height: 100px; width: 150px; margin-top: 0px; padding-top: 0px;" 
								src="https://s3iconimages.mymedicine.com.mm/PaymentDeclinedImage.svg">
							<p style="font-size: 12px; font-weight: 400;">
                                <span style="font-size: 10px;">{{ $t("Customer.Home.Encountered_Error") }}</span>
                            </p>
                            <div style="display: flex; justify-content: center;">
                                <v-btn class="goHomeBtn" elevation="0" style="width: 320px;" @click="goToEpharmacyHome()">
                                    <span class="white-color-text">{{ $t("Customer.MyOrders.Continue_Shopping") }}</span>
                                </v-btn>
                            </div>
                        </div>
                        <div class="innerDivMobile" v-if="!isDesktopMode && orderPlacedCode==1">
                            <img style="width: 130px; height: 130px;" 
                                src="https://s3iconimages.mymedicine.com.mm/OrderSuccessImageFull.svg">
                            <p style="font-size: 20px; font-weight: 600; width: 200px;">
                                {{ $t("Customer.Home.Order_Placed_Successfully") }}
                            </p>
                            <p style="font-size: 16px; font-weight: 400;">
                                {{ $t("Customer.Home.Your_Order_ID_is") }}
                                <span style="font-weight: 600;"> {{ truncateOrderID(orderID) }} </span>
                                <br>
                                <span style="font-size: 14px; color: #828282; font-weight: 400;">
                                    {{ $t("Customer.MyOrders.You_will_receive_an_order_confirmation_email_with_details_of_your_order_shortly") }}
                                </span>
                            </p>
                            <v-btn class="bigButton goHomeBtn" elevation="0" @click="goToEpharmacyHome()">
                                <span class="white-color-text">{{ $t("Customer.MyOrders.Continue_Shopping") }}</span>
                            </v-btn>
                            <div style="padding: 10px; margin-bottom: -5px;"></div>
                            <v-btn class="bigButton viewOrderDetailsBtn" elevation="0" @click="redirectToParticularOrder()">
                                {{ $t("Customer.MyOrders.View_Order_Details") }}
                            </v-btn>
                        </div>
						<div class="innerDivMobile" v-if="!isDesktopMode && orderPlacedCode==2">
                            <img style="width: 130px; height: 130px;" 
                                src="https://s3iconimages.mymedicine.com.mm/PaymentProcessingImage.svg">
                            <p style="font-size: 20px; font-weight: 600; width: 200px;">
                                {{ $t("Customer.Home.Payment_Processing") }}
                            </p>
                            <p style="font-size: 16px; font-weight: 400;">
                                {{ $t("Customer.Home.Your_Order_ID_is") }}
                                <span style="font-weight: 600;"> {{ truncateOrderID(orderID) }} </span>
                                <br>
                                <span style="font-size: 14px; color: #828282; font-weight: 400;">
                                    {{ $t("Customer.Home.Please_Check_Later") }}
                                </span>
                            </p>
                            <v-btn class="bigButton goHomeBtn" elevation="0" @click="goToEpharmacyHome()">
                                <span class="white-color-text">{{ $t("Customer.MyOrders.Continue_Shopping") }}</span>
                            </v-btn>
                        </div>
						<div class="innerDivMobileDeclined" v-if="!isDesktopMode && orderPlacedCode==3">
                            <img style="width: 130px; height: 130px;" 
                                src="https://s3iconimages.mymedicine.com.mm/PaymentDeclinedImage.svg">
                            <p style="font-size: 20px; font-weight: 600; width: 200px;">
                                {{ $t("Customer.Home.Your_Payment_Has_Been_Declined") }}
                            </p>
                            <p style="font-size: 16px; font-weight: 400;">
                                <span style="font-size: 14px; color: #828282; font-weight: 400;">
                                    {{ $t("Customer.Home.Encountered_Error") }}
                                </span>
                            </p>
                            <v-btn class="bigButton goHomeBtn" elevation="0" @click="goToEpharmacyHome()">
                                <span class="white-color-text">{{ $t("Customer.MyOrders.Continue_Shopping") }}</span>
                            </v-btn>
                        </div>
                    </div>

                    <!-- Segment 6 MultipleStylesComponent Testing -->
                    <!-- <div class="products-segment">
                        <MultipleItemsListingsComponent
                            :data="dataForListingsComponent"
                            listings_type="LISTINGS-ONE-ROW"
							
                            class="rounded-lg"
                        >
                        </MultipleItemsListingsComponent>
                    </div> -->
					<div class="products-segment">
                          <div class="pa-4" v-if="bestSalersLoader">
                              <v-skeleton-loader elevation="0" type="heading"></v-skeleton-loader>

                              <div class="d-flex">
                                  <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
                                  <v-skeleton-loader elevation="0" type="list-item" class="one-third-width"></v-skeleton-loader>
                              </div>

                              <div class="d-flex">
                                  <v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
                                  <v-skeleton-loader elevation="0" type="card" width="180" height="280" class="mx-2"></v-skeleton-loader>
                              </div>
                          </div>
                          <promotion-products-component  v-else class="segment-5"
                              :data="productsList1"
                              listings_type="LISTINGS-ONE-ROW"
                          ></promotion-products-component>
                    </div>
                </div>
            </div>
			<v-divider v-if="isDesktopMode"></v-divider>

			<!-- Footer -->
			<div class="pa-4" v-if="isDesktopMode">
				<common-home-footer></common-home-footer>
			</div>
		</div>
	</v-app>
</template>

<script>
import axios from 'axios';
import { axios_auth_instance_epharmacy_customer } from '../utils/axios_utils';
import { handleError } from '../utils/utils';

export default {
	name: 'OrderSuccessPage',
	components: {
		'common-home-header': () => import('../components/navigationComponents/commonHomeHeader.vue'),
		'header-tabs-component': () => import('../components/navigationComponents/headerTabsComponent.vue'),
		'MultipleItemsListingsComponent': () => import('../components/ePharmacyComponents/multipleStyleListingsComponent.vue'),
		'common-home-footer': () => import('../components/navigationComponents/commonHomeFooter.vue'),
      	'promotion-products-component': () => import('../components/ePharmacyComponents/multipleStyleListingsComponent.vue'),
	
	},
	data() {
		return {
            isDesktopMode: false,
			screenWidth: window.innerWidth,
			isUserLoggedIn: false,
			doctorsList: [],
			productsList: [],
			productSliderData1: null,
			productSliderData2: null,
			bgColorOfComponent: '',
			buttonTextForComponent: '',
            bgColorFull: '',
			orderID: '',
			userID: '',
			currentCustomer: '',
			orderPlacedCode: 2,
			dataForListingsComponent: {},
			callpermissionflag: false,
			redirectOrderID: null,
			productsList1: [],
			bestSalersLoader: true,
			error_enable: true,
			auth_error_enable: true,
		}
	},
    beforeDestroy() {
        window.removeEventListener("resize", this.checkScreenMode);
    },
	mounted() {
		this.currentCustomer = this.$cookies.get("customerToken");

		if(!this.currentCustomer) {
			if (!window.xm) {
                this.$router.push({
                    name: 'CustomerHomeScreen'
                });
            } else {
                this.$router.push({
                    name: 'CommonHomePage'
                });
            }
		} else {
			this.$i18n.locale = this.$store.state.locale;

			this.isUserLoggedIn = true;

			// displaying the order status
			this.getOrderStatus();
			
			this.checkScreenMode();
			window.addEventListener("resize",  this.checkScreenMode);

			window.addEventListener('resize', this.handleResize);
			
			this.bgColorFull = "red";

			if (this.screenWidth <= 600) {
				this.banners = [{
					image_url: "https://s3iconimages.mymedicine.com.mm/small_banner_test.svg",
					route_to: 'ALL_CATEGORIES',
				}];
			} else if (this.screenWidth > 600 && this.screenWidth <= 1264) {
				this.banners = [{
					image_url: "https://s3iconimages.mymedicine.com.mm/big_banner_test.svg",
					route_to: 'ALL_CATEGORIES',
				}];
			} else {
				this.banners = [{
					image_url: "https://s3iconimages.mymedicine.com.mm/big_banner_test.svg",
					route_to: 'ALL_CATEGORIES',
				}];

			}
			axios_auth_instance_epharmacy_customer.get('/products/collections', {params: {
              	minAmount: 0,
              	maxAmount: 150000,
              	collectionList: ['1']
          	}}).then((res) => {
				this.productsList1 = {
					"type": "LISTINGS-ONE-ROW",
					"item_type": "COLLECTIONS",
					"title": "Best Sellers",
					"icon": 'https://s3iconimages.mymedicine.com.mm/bestSellersIcon.svg',
					"background": "white",
					"items": [
						{
							"items": res.data.data || [],
							"tab_link": "1",
						}
					],
					"redirection_label": "View More",
					"redirection_label_burmese": "နောက်ထပ်",
					"displayJumpLink": true,
				};
              	this.bestSalersLoader = false;
          	}).catch((error) => {
            	this.bestSalersLoader = false;
				if (error.response && error.response.status == 401) {
					console.log('Unauthorized, Login again.');
					this.$cookies.remove('customerToken');
					this.auth_error_enable = true;
            	} else {
					this.error_enable = true;
            	}
          	});
			this.buttonTextForComponent = 'Buy Now';
			this.bgColorOfComponent = 'white';

			this.dataForListingsComponent = {
				title: 'MedicineZZZ',
				item_type: 'PRODUCTS',
				icon: 'https://s3iconimages.mymedicine.com.mm/explore_by_specialities_home_icon.svg',
				items: this.productsList,
				background: this.bgColorOfComponent,
				redirectionLink: 'hewshg3iugiuyg',
				redirectLabel: 'Explore Products',
				redirectLabelMM: 'ဆေးဝါးများရှာဖွေရန်'
			};
			this.userPageViewTable();
		}
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                    pageName : 'orderSuccessPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'orderSuccessPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
		truncateOrderID(fullString) {
			try {
			const prefix = 'epharmacy_';
			const index = fullString.indexOf(prefix);

			if(index !== -1) {
				const substring = fullString.slice(index + prefix.length);
				const pipeIndex = substring.indexOf('||');

				if (pipeIndex !== -1)
					return substring.slice(0, pipeIndex);
				else
					return substring;
			} else {
				return fullString;
			}
			} catch (error) {
				console.log(error);
			}
			
		},
		goToEpharmacyHome() {
			if (!window.xm) {
				this.$router.push({
					name: 'CustomerHomeScreen'
				});
			} else {
				this.$router.push({
					name: "ePharmacyHomePage"
				});
			}
		},
		getOrderStatus() {
			this.orderID = this.$route.query.merch_order_id;
			const encodedOrderID = encodeURIComponent(this.orderID);
			let count = 0;
			let endInterval = null;
			endInterval = setInterval(() => {
				axios_auth_instance_epharmacy_customer.get("/orders/verifyOrder" + "?orderID=" + encodedOrderID)
				.then((getOrderStatusResponse) => {
					this.redirectOrderID = getOrderStatusResponse.data.data.order_id;
					count++;
					this.orderPlacedCode = getOrderStatusResponse.data.data.orderResponseCode;
					if (this.orderPlacedCode === 1 || count === 10) {
						clearInterval(endInterval);
					}
					this.callpermissionflag = true;
				}).catch((getOrderStatusError) => {
					count++;
					if (count < 10) {
						console.log('Trying again');
					} else {
						this.orderPlacedCode = 3;
						clearInterval(endInterval);
						if (getOrderStatusError.status === 400) {
							console.log('Error in getting PaymentStatus')
						} else if (getOrderStatusError.status === 401) {
							this.$cookies.remove('customerToken');
							if (!window.xm) {
								this.$router.push({
									name: 'CustomerHomeScreen'
								});
							} else {
								this.$router.push({
									name: 'CommonHomePage'
								});
							}
						} else {
							console.log('getOrderStatusError: ', getOrderStatusError);
						}
						handleError(getOrderStatusError, this.$router, 'verifyOrder', 'CommonHomePage', 'customerToken');
					}
				})
			}, 3000)
		},
        checkScreenMode() {
            this.isDesktopMode = window.matchMedia("(min-width: 500px)").matches;
            if(this.isDesktopMode) {
                mainContainer.style.backgroundColor = "#F4F6FA";
            } else {
                mainContainer.style.backgroundColor = "white";
            }
        },
		handleResize() {
			this.screenWidth = window.innerWidth;
			if (this.screenWidth <= 600) {
				this.banners = [{
					image_url: "https://s3iconimages.mymedicine.com.mm/small_banner_test.svg",
					route_to: 'ALL_CATEGORIES',
				}];
			} else if (this.screenWidth > 600 && this.screenWidth <= 1264) {
				this.banners = [{
					image_url: "https://s3iconimages.mymedicine.com.mm/big_banner_test.svg",
					route_to: 'ALL_CATEGORIES',
				}];
			} else {
				this.banners = [{
					image_url: "https://s3iconimages.mymedicine.com.mm/big_banner_test.svg",
					route_to: 'ALL_CATEGORIES',
				}];
			}
			this.bannersComponentKey++;
		},
		handleCategorySizeChange() {
			this.specializationComponentKey++;
		},
		redirectToParticularOrder() {
			this.$router.push({
				path: '/customer/orderDetailsPage/' + this.redirectOrderID,
			})
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../scss/global.scss';
@import '../scss/classes.scss';

.bigButton{
    width: 100%;
}
.innerDiv {
    width: 400px;
    height: 265px;
    margin-top: 20px;
    margin-bottom: 45px;
	color: #000000;
}
.innerDivWaiting {
    width: 330px;
    height: 240px;
    margin-top: 20px;
    margin-bottom: 45px;
	color: #000000;
}
.innerDivDeclined {
	width: 330px;
    height: 225px;
    margin-top: 20px;
    margin-bottom: 45px;
	color: #000000;
}
.innerDivMobile {
    width: 100%;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
	color: #000000;
}
.innerDivMobileDeclined {
	width: 100%;
    margin-top: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
	color: #000000;
}
.viewOrderDetailsBtn {
    color: #48ACEF;
    background-color: white !important;
    border: 1px solid #48ACEF !important;
    border-radius: 4px;
    text-transform: none;
}
.goHomeBtn {
    color: white;
    background-color: #48ACEF !important;
    border-radius: 4px;
    text-transform: none;
}
.container {
    // background-color: red;
    padding-top: 30px;
}
.order-success-segment{
    background-color: white;
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: calc( $content-padding-mobile / 2);
		margin-left: $margin-header-x-mobile;
		margin-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: $content-padding-medium;
		padding-bottom: $content-padding-medium;
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;

		border-radius: 8px;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: $content-padding-web;
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;

		border-radius: 8px;
	}
}
.navigation-tabs-segment {
	@media (max-width: 600px) {
        display: none;
	}
}
.products-segment {
	@media (max-width: 600px) {
        padding-top: calc( $content-padding-mobile / 2);
		padding-bottom: calc( $content-padding-mobile / 2);
		margin-left: $margin-header-x-mobile;
		margin-right: $margin-header-x-mobile;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		padding-top: $content-padding-medium;
		padding-bottom: $content-padding-medium;
		margin-left: $margin-header-x-medium;
		margin-right: $margin-header-x-medium;

		border-radius: 8px;
	}

	@media (min-width: 1265px) {
		padding-top: $content-padding-web;
		padding-bottom: $content-padding-web;
		margin-left: $margin-header-x-web;
		margin-right: $margin-header-x-web;

		border-radius: 8px;
	}
	.products-component-2 {
  //  pb-4 pb-lg-8
  @media (max-width: 800px) {
      padding-bottom: 16px;
  }

  @media (min-width: 801px) and (max-width: 1264px) {
      margin: 16px 0px;
      padding-bottom: 16px;
  }

  @media (min-width: 1265px) {
      margin: 32px 0px;
      padding-bottom: 32px;
  }
}
}
</style>

<style lang="scss">
@import '../scss/global.scss';
@import '../scss/classes.scss';

</style>